/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Source Sans Pro";

  /** primary **/
  --ion-color-primary: #009ee0;
  --ion-color-primary-rgb: 0,158,224;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #008bc5;
  --ion-color-primary-tint: #1aa8e3;

  /** secondary **/
  --ion-color-secondary: #004169;
  --ion-color-secondary-rgb: 0,65,105;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #00395c;
  --ion-color-secondary-tint: #1a5478;

  /** tertiary **/
  --ion-color-tertiary: #EAB414;
  --ion-color-tertiary-rgb: 234,180,20;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #ce9e12;
  --ion-color-tertiary-tint: #ecbc2c;

  /** success **/
  --ion-color-success: #2ECC70;
  --ion-color-success-rgb: 46,204,112;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28b463;
  --ion-color-success-tint: #43d17e;

  /** warning **/
  --ion-color-warning: #FFC106;
  --ion-color-warning-rgb: 255,193,6;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0aa05;
  --ion-color-warning-tint: #ffc71f;

  /** danger **/
  --ion-color-danger: #D0000F;
  --ion-color-danger-rgb: 208,0,15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #b7000d;
  --ion-color-danger-tint: #d51a27;

  /** dark **/
  --ion-color-dark: #737373;
  --ion-color-dark-rgb: 115,115,115;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #656565;
  --ion-color-dark-tint: #818181;

  /** medium **/
  --ion-color-medium: #EFEFEF;
  --ion-color-medium-rgb: 239,239,239;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #d2d2d2;
  --ion-color-medium-tint: #f1f1f1;

  /** light **/
  --ion-color-light: #F4F6FC;
  --ion-color-light-rgb: 244,246,252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8de;
  --ion-color-light-tint: #f5f7fc;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
  body {
    /** primary **/
    --ion-color-primary: #009ee0;
    --ion-color-primary-rgb: 0,158,224;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #008bc5;
    --ion-color-primary-tint: #1aa8e3;

    /** secondary **/
    --ion-color-secondary: #0F416B;
    --ion-color-secondary-rgb: 0,65,105;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #00395c;
    --ion-color-secondary-tint: #1a5478;

    /** tertiary **/
    --ion-color-tertiary: #EAB414;
    --ion-color-tertiary-rgb: 234,180,20;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #ce9e12;
    --ion-color-tertiary-tint: #ecbc2c;

    /** success **/
    --ion-color-success: #2ECC70;
    --ion-color-success-rgb: 46,204,112;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #28b463;
    --ion-color-success-tint: #43d17e;

    /** warning **/
    --ion-color-warning: #FFC106;
    --ion-color-warning-rgb: 255,193,6;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0aa05;
    --ion-color-warning-tint: #ffc71f;

    /** danger **/
    --ion-color-danger: #D0000F;
    --ion-color-danger-rgb: 208,0,15;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #b7000d;
    --ion-color-danger-tint: #d51a27;

    /** dark **/
    --ion-color-dark: #737373;
    --ion-color-dark-rgb: 115,115,115;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #656565;
    --ion-color-dark-tint: #818181;

    /** medium **/
    --ion-color-medium: #EFEFEF;
    --ion-color-medium-rgb: 239,239,239;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #d2d2d2;
    --ion-color-medium-tint: #f1f1f1;

    /** light **/
    --ion-color-light: #F4F6FC;
    --ion-color-light-rgb: 244,246,252;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8de;
    --ion-color-light-tint: #f5f7fc;
  }
}

/* This is for all of the fontawesome icons */
.svg-inline--fa {
  min-width: 18px;
  min-height: 18px;
}